body {
	background-color: @body-bg-color;
}

h1 {
	color: @h1-main-color;
}

.page-slide-wrapper {
	right: 0;
	position: relative;
	transition: right .2s ease-out;
	background-color: @body-bg-color;
	#menu_toggle_view_state:checked + &,
	#menu_toggle_view_state:checked ~ & {
		right: 90%;
		width: 100%;
		height: auto;
		position: fixed;
		box-shadow: 4px 0 5px -2px @gray47;
	}
}

.page-slide-blocker {
	top: 0;
	right: 0;
	height: 0;
	z-index: 2;
	width: 100%;
	.opacity(50);
	display: block;
	position: fixed;
	background-color: @black;
	transition: right .2s ease-out;
	#menu_toggle_view_state:checked + .page-slide-wrapper &,
	#menu_toggle_view_state:checked ~ .page-slide-wrapper & {
		right: 90%;
		height: 100%;
	}
}

.wrapper {
	z-index: 0;
	position: relative;
	background-color: @body-bg-color;
}

.text-center {
	text-align: center;
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.page-slide-blocker {
		#menu_toggle_view_state:checked + .page-slide-wrapper &,
		#menu_toggle_view_state:checked ~ .page-slide-wrapper & {
			right: 0;
			height: 100%;
		}
	}

	.page-slide-wrapper {
		#menu_toggle_view_state:checked + &,
		#menu_toggle_view_state:checked ~ & {
			right: 0;
			width: auto;
			box-shadow: none;
			position: relative;
		}
	}
}
