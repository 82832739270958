@pdf-bg: url('/pub/images/base/pdf-bg-texture.png') scroll no-repeat center center @pdf-viewer-gray;

body.order-page,
.order-page.wrapper {
	background: @pdf-bg;
}
.order-header {
	padding: 10px;
	color: @white;
	background-color: @theme-blue;
	h1 {
		color: @white;
	}
	a {
		color: @white;
		text-decoration: none;
	}
	.download {
		display: none;
	}
	.order-info,
	.proof-version {
		vertical-align: top;
		display: inline-block;
	}
	.order-info {
		width: 78%;
		margin-right: 5px;
		width: calc(100% - 70px);
		p {
			font-size: 14px;
			margin: 0 0 5px;
		}
		h2 {
			margin: 0;
			font-size: 15px;
			&+ h1 {
				font-size: 25px;
				font-weight: 500;
				text-transform: uppercase;
			}
		}
		h1 {
			margin: 0;
			font-size: 20px;
			word-break: break-all;
		}
	}
	.proof-version {
		width: 54px;
		text-align: center;
		p {
			font-size: 14px;
			margin: 0 0 5px;
			&.version {
				margin: 0;
				font-size: 45px;
				font-weight: 700;
				line-height: 45px;
			}
		}
	}
}
.status-bar {
	color: @white;
	padding: 10px;
	font-size: 25px;
	font-weight: 300;
	&.approved {
		background-color: @frontend_approvegreen;
		border-top: 1px solid lighten(@frontend_approvegreen, 27%);
		text-shadow: 1px 1px 1px darken(@frontend_approvegreen, 22%);
		span:before {
			color: @forestgreen;
			.font-icon('\f00c', '10px');
		}
	}
	&.rejected {
		background-color: @frontend_rejectionred;
		border-top: 1px solid lighten(@frontend_rejectionred, 27%);
		text-shadow: 1px 1px 1px darken(@frontend_rejectionred, 22%);
		span:before {
			color: @firebrick;
			.font-icon('\f023', '10px');
		}
	}
}
.pdf-viewer-wrapper {
	z-index: 0;
	padding: 25% 50%; // fallback 2
	padding: calc((100% - 162px) / 2) 50%; // fallback 1
	padding: calc((100vh - 162px) / 2) 50%;
	position: relative;
	background: @pdf-bg;
	object, embed, iframe {
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}
}
.order-actions {
	text-align: center;
	padding: 30px 10px 15px;
	background-color: @gray29;
	.min-maximizer {
		display: none;
	}
	.flash-message {
		top: 0;
		left: 0;
		width: 100%;
		color: @white;
		display: block;
		padding: 2px 0;
		text-align: center;
		position: absolute;
		box-sizing: border-box;
	}
	.lb-wrapper {
		width: 120px;
		margin: 0 14px;
		display: inline-block;
		.btn {
			width: 100%;
			height: 60px;
			color: @white;
			display: block;
			font-weight: 700;
			border-radius: 4px;
			box-sizing: border-box;
			padding: 14px 16px 18px;
			&.reject {
				box-shadow: 0 4px 5px @gray13;
				background-color: @sgilightgray;
				text-shadow: 1px 1px 1px darken(@sgilightgray, 30%);
				border-bottom: 5px solid darken(@sgilightgray, 30%);
			}
			&.approve {
				box-shadow: 0 4px 5px @gray13;
				background-color: @frontend_approvegreen;
				text-shadow: 1px 1px 1px darken(@frontend_approvegreen, 15%);
				border-bottom: 5px solid darken(@frontend_approvegreen, 15%);
			}
		}
	}
	// Only do this if Javascript is enabled!
	.js & {
		right: 0;
		z-index: 1;
		width: 100%;
		bottom: -105px;
		position: fixed;
		background-color: @black;
		transition: right .2s ease-out;
		background-color: rgba(0, 0, 0, .5);
		.min-maximizer {
			left: 10px;
			width: 60px;
			height: 40px;
			bottom: 100%;
			color: @white;
			display: block;
			padding: 2px 0;
			font-size: 30px;
			cursor: pointer;
			position: absolute;
			box-sizing: border-box;
			border-radius: 10px 10px 0 0;
			background-color: @theme-blue;
			text-shadow: 1px 1px 1px darken(@theme-blue, 5%);
			&:before {
				.font-icon('\f0d8');
			}
		}
		&.active {
			.min-maximizer {
				&:before {
					.font-icon('\f0d7');
				}
			}
		}
	}
	.js #menu_toggle_view_state:checked + .page-slide-wrapper &,
	.js #menu_toggle_view_state:checked ~ .page-slide-wrapper & {
		right: 90%;
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.order-header {
		padding: 10px;
		.order-info {
			width: auto;
			max-width: 50%;
			// max-width: calc(100% - 70px);
			margin-right: 20px;
			padding: 0 20px;
			border-right: 1px solid @white;
			p {
				font-size: 14px;
				margin: 0 0 5px;
			}
			h2 {
				margin: 0;
				font-size: 20px;
				&+ h1 {
					font-size: 35px;
				}
			}
			h1 {
				font-size: 25px;
			}
		}
		.proof-version {
			position: relative;
			text-align: center;
			p {
				font-size: 14px;
				margin: 0 0 5px;
				&.version {
					margin: 0;
					font-size: 62px;
					font-weight: 700;
					line-height: 62px;
				}
			}
			&:before {
				width: 1px;
				content: '';
				left: -25px;
				height: 100%;
				display: block;
				position: absolute;
				background: @white;
			}
		}
		.download {
			float: right;
			display: block;
			text-align: center;
			a {
				.opacity(100);
				color: @white;
				display: block;
				padding: 12px 18px;
				transition: all .25s linear;
				&:before {
					display: block;
					font-size: 40px;
					.font-icon('\f0ed');

				}
				&:hover, &:focus {
					.opacity(80);
				}
			}
		}
	}
	.status-bar {
		padding: 10px 20px;
	}
	.pdf-viewer-wrapper {
		padding: 25% 50%; // fallback 2
		padding: calc((100% - 194px) / 2) 50%; // fallback 1
		padding: calc((100vh - 194px) / 2) 50%;
	}
	.order-actions {
		.lb-wrapper {
			width: 160px;
			.btn {
				&.reject {
					&:hover, &:focus {
						background-color: lighten(@sgilightgray, 10%);
					}
					&:active {
						background-color: darken(@sgilightgray, 10%);
					}
				}
				&.approve {
					&:hover, &:focus {
						background-color: lighten(@frontend_approvegreen, 10%);
					}
					&:active {
						background-color: darken(@frontend_approvegreen, 10%);
					}
				}
			}
		}
		.js #menu_toggle_view_state:checked + .page-slide-wrapper &,
		.js #menu_toggle_view_state:checked ~ .page-slide-wrapper & {
			right: 0;
		}
	}
}
