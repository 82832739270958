.lb-wrapper {
	&.lb-approvalModal {
		.lb-body-wrapper  {
			width: 320px;
			height: auto;
			max-height: 600px;
			border-radius: 10px;
			.lb-body {
				padding: 0;
				overflow: hidden;
				.heading {
					margin: 0;
					color: @white;
					padding: 10px 0;
					font-size: 32px;
					font-weight: 500;
					text-align: center;
					background-color: @frontend_approvegreen;
				}
				.info-wrapper {
					height: 88%; // fallback
					height: calc(100% - (58px + 10px));
					padding: 0 20px 25px;
					box-sizing: border-box;
					overflow: auto;
					.read-carefully {
						color: @gray29;
						margin: 15px 0;
						font-size: 20px;
						font-weight: 600;
						text-align: center;
					}
					.message {
						font-size: 15px;
						margin: 15px auto 25px;
						text-align: left;
					}
					.acknowledgement-wrapper {
						padding: 10px;
						border-top: 1px solid @sgilightgray;
						cursor: pointer;
						label {
							cursor: pointer;
							font-size: 12px;
							margin: 0;
							text-align: left;
						}
						input[type='checkbox'] {
							cursor: pointer;
						}
					}
					.btn {
						color: @white;
						margin: 10px 0 0;
						&.link {
							text-decoration: underline;
							&.lb-closer {
								color: @gray47;
								display: block;
								height: auto;
								font-size: 1em;
								font-weight: 400;
								padding: 10px;
							}
						}
						&.lb-submit {
							background-color: @frontend_approvegreen;
							border: none;
							border-bottom: 6px solid darken(@frontend_approvegreen, 20%);
							margin-top: 5px;
							text-shadow: 1px 1px 1px darken(@frontend_approvegreen, 10%);
						}
					}
				}
			}
		}
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.lb-wrapper {
		&.lb-approvalModal {
			.lb-body-wrapper  {
				width: 360px;
				.lb-body {
					.info-wrapper {
						.read-carefully {
							font-size: 20px;
						}
						.btn {
							&.link {
								&:hover, &:focus {
									text-decoration: none;
								}
							}
							&.lb-submit {
								&:hover, &:focus {
									background-color: lighten(@frontend_approvegreen, 5%);
								}
								&:active {
									background-color: darken(@frontend_approvegreen, 5%);
								}
							}
						}
					}
				}
			}
		}
	}
}
