.order-activity {
	.tbl-div {
		font-size: 14px;
		.tbl-row {
			.tbl-col {
				&.index {
					float: left;
					margin-right: 5px;
					font-weight: 700;
				}
				&.date {
					margin: 0 0 8px;
					font-weight: 700;
					text-align: right;
					border-bottom: 1px solid @gray73;
				}
			}
		}
	}
}


/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.order-activity {
		.tbl-div {
			.tbl-row {
				.tbl-col {
					&.index {
						float: none;
						margin-right: 0;
						font-weight: 400;
					}
					&.date {
						margin: 0;
						font-weight: 400;
						text-align: left;
						border-bottom: 1px solid darken(@lightgray, 30%);
					}
				}
			}
			&.data-tbl {
				.tbl-row {
					&:hover {
						.tbl-col {
							&.date {
								border-bottom-color: darken(@aliceblue, 30%);
							}
						}
					}
				}
			}
		}
	}
}
