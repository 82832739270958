.opacity(@a) {
	filter: "alpha(opacity=@{a})";
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@{a})";
	opacity: (@a/100);
}

.font-icon(@icon:'', @margin: '2px', @dir: 'right') {
	content: @icon;
	font-family: 'FontAwesome';
	& when (@dir = 'left') {
		margin-left: ~"@{margin}";
	}
	& when (@dir = 'right') {
		margin-right: ~"@{margin}";
	}
}

.rgbaColor(@rgb, @a:1) {
	background-color: rgb(red(@rgb), green(@rgb), blue(@rgb));
	background-color: rgba(red(@rgb), green(@rgb), blue(@rgb),@a);
}

.col-width(@size, @base: 100) {
	& when (isnumber(@size)) and (@size = 12) {
		width: 100% !important;
	}
	& when (isnumber(@size)) and (@size < 12) {
		width: calc((@base/12)*@size*1%) !important;
		// width: calc((@base/12)*@size*1%) !important;
	}
}

.center(@horizontal: true, @vertical: true) {
	& when ( @horizontal = true ) and ( @vertical = true ) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	& when ( @horizontal = true ) and ( @vertical = false ) {
		left: 50%;
		transform: translate(-50%, 0);
	}
	& when ( @horizontal = false ) and ( @vertical = true ) {
		top: 50%;
		transform: translate(0, -50%);
	}
}

.center-notransform(@horizontal: true, @vertical: true) {
	& when ( @horizontal = true ) and ( @vertical = true ) {
		top: 50%;
		left: 50%;
		margin: 0;
		margin-top: -200px;
		margin-left: -250px;
	}
	& when ( @horizontal = true ) and ( @vertical = false ) {
		left: 50%;
		margin-right: 0;
		margin-left: -250px;
	}
	& when ( @horizontal = false ) and ( @vertical = true ) {
		top: 50%;
		margin-bottom: 0;
		margin-top: -200px;
	}
}
