.pagination-bar {
	margin: 10px 0;

	&:after {
		content: '';
		clear: both;
		float: none;
		display: block;
	}

	& .pagination-info {
		padding: 5px;
		font-size: 14px;

		& span {
			padding: 0 10px;

			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
		}
	}

	& .next-page,
	& .prev-page,
	& .page-number {
		float: left;
		color: @gray29;
		overflow: hidden;
		padding: 4px 14px;
		text-decoration: none;
		background-color: @gray93;
		transition: all .2s linear;
		border: 1px solid darken(@gray93, 15%);

		&:hover, &:focus {
			transform: scale(1.05);
			background-color: lighten(@gray93, 5%);
		}
		&:active {
			background-color: darken(@gray93, 5%);
		}
		&.disabled {
			cursor: default;
			color: darken(@gray93, 20%);
			&:hover, &:focus {
				transform: none;
				background-color: @gray93;
			}
		}
		&.current {
			color: @white;
			font-weight: bold;
			background-color: @dodgerblue;
			border-color: darken(@dodgerblue, 15%);

			&:hover, &:focus {
				transform: none;
				background-color: @dodgerblue;
			}
		}
	}

	& .next-page {
		border-radius: 0 5px 5px 0;
	}
	& .prev-page {
		border-radius: 5px 0 0 5px;
	}
}
