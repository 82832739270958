form {
	margin: auto;
	max-width: 1200px;

	&:after {
		content: '';
		clear: both;
		float: none;
		display: block;
	}
}

:-moz-placeholder { // Firefox 18-
	color: @silver;
}
::-moz-placeholder { //Firefox 19+
	color: @silver;
}
:-ms-input-placeholder {  //IE 10+
	color: @silver;
}
::-webkit-input-placeholder { //Chrome/Opera/Safari
	color: @silver;
}

.field {
	margin: 10px 0;
	display: block;
	position: relative;
	box-sizing: border-box;
	&.col {
		padding: 0 4px;
		display: inline-block;
	}
	&.split {
		width: 50%;
		margin: 5px;
		display: inline-block;
	}
	&.thirds {
		margin: 5px;
		width: 33.33%;
		display: inline-block;
	}
	&.quarters {
		width: 25%;
		margin: 5px;
		display: inline-block;
	}
	input {
		&[type="url"],
		&[type="date"],
		&[type="text"],
		&[type="email"],
		&[type="phone"],
		&[type="search"],
		&[type="number"],
		&[type="password"] {
			height: 40px;
			.form-field-styling();
			&[readonly] {
				.form-field-disabled-styling();
			}
			&.sm {
				height: 30px;
				padding: 3px 6px;
			}
		}
	}
	input[type="date"] {
		display: inline-block;
		font-size: 11px;
		width: 115px !important;
	}
	textarea {
		height: auto;
		.form-field-styling();
		&[readonly="true"] {
			.form-field-disabled-styling();
		}
		&[data-gramm] {
			transition: all .2s linear !important;
		}
	}
	.select-wrapper {
		// display: inline;
		position: relative;
		white-space:nowrap;
		text-overflow:ellipsis;
		select {
			.form-field-styling();
			height: 40px;
			appearance: none;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			background-color: @white;
			padding: 6px 24px 6px 6px;
			vertical-align: middle;
			option {
				white-space:nowrap;
				text-overflow:ellipsis;
			}
			&::-ms-expand {
				display: none;
			}
		}
		&:after {
			top: 12px;
			z-index: 9;
			right: 10px;
			.opacity(50);
			width: auto;
			height: auto;
			font-size: 18px;
			position: absolute;
			.font-icon('\f0d7');
			pointer-events: none;
		}
		.ie9 &,
		.ie8 &,
		.ie7 &,
		.lt-ie7 & {
			&:before {
				top: 2px;
				z-index: 7;
				right: 3px;
				width: 45px;
				content: '';
				height: 35px;
				position: absolute;
				background: @white;
				pointer-events: none;
			}
		}
		&.sm {
			select {
				height: 30px;
				padding: 3px 24px 3px 6px;
			}
			&:after {
				top: 6px;
			}
			.ie9 &,
			.ie8 &,
			.ie7 &,
			.lt-ie7 & {
				&:before {
					height: 25px;
				}
			}
		}
	}
	// checkbox
	&.checkbox-holder {
		input[type='checkbox'] {
			&.hidden {
				& + label.checkbox {
					cursor: pointer;
					&.right {
						text-align: right;
					}
					&:before {
						color: @gray;
						font-size: 24px;
						cursor: pointer;
						vertical-align: middle;
						.font-icon('\f096', '15px');
						&:hover {
							color: @black;
						}
					}
				}
				&:checked + label.checkbox {
					&.right {
						&:before {
							right: -3.5px;
							.font-icon('\f046', '15px');
						}
					}
					&:before {
						margin-left: 1px;
						position: relative;
						color: @forestgreen;
						.font-icon('\f046', '10.5px');
					}
				}
			}
		}
	}
}

label {
	margin: 5px 0;
	display: inline-block;
	&.required {
		&:before {
			margin: 0 2px;
			font-size: 8px;
			color: @firebrick;
			.font-icon('\f069');
			vertical-align: super;
		}
	}
}

// styling for form entry fields
.form-field-styling {
	outline: 0;
	width: 100%;
	padding: 6px;
	outline: none;
	border-radius: 3px;
	box-sizing: border-box;
	transition: all .2s linear;
	border: 1px solid darken(@gainsboro, 20%);
	&:hover {
		border-color: darken(@gainsboro, 35%);
		box-shadow: inset 0 0 2px darken(@gainsboro, 15%);
	}
	&:focus,
	&:active {
		border: 1px solid darken(@dodgerblue, 10%);
		box-shadow: inset 0 0 2px darken(@dodgerblue, 10%);
	}
}

.form-field-disabled-styling {
	border: 1px solid @gray83;
	background-color: @gray93;
	&:hover,
	&:focus,
	&:active {
		outline: none;
		box-shadow: none;
		background-color: @gray93;
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	input {
		&[type="submit"] {
			width: auto;
			float: right;
		}
	}
}
