.spinner-holder {
	position: relative!important;
	.spinner {
		position: absolute;
		.csstransforms & {
			.center();
		}
		.no-csstransforms & {
			.center-notransform();
		}
	}
}
