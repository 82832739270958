.lb-wrapper {
	&.lb-rejectionModal {
		.lb-body-wrapper  {
			width: 320px;
			height: auto ;
			max-height: 600px;
			border-radius: 10px;
			.lb-body {
				padding: 0;
				overflow: hidden;
				.heading {
					margin: 0;
					color: @white;
					padding: 10px 0;
					font-size: 32px;
					font-weight: 500;
					text-align: center;
					background-color: @frontend_rejectionred;
				}
				.info-wrapper {
					height: 88%; // fallback
					height: calc(100% - (58px + 10px));
					padding: 0 20px 25px;
					box-sizing: border-box;
					overflow: auto;
					.read-carefully {
						color: @gray29;
						margin: 15px 0;
						font-size: 17px;
						font-weight: 600;
						text-align: center;
					}
					.message {
						font-size: 15px;
						text-align: left;
					}
					label {
						display: none;
					}
					textarea {
						color: @white;
						border: 1px solid @black;
						background-color: @gray23;
						&:-moz-placeholder { // Firefox 18-
							color: @gray60;
						}
						&::-moz-placeholder { //Firefox 19+
							color: @gray60;
						}
						&:-ms-input-placeholder {  //IE 10+
							color: @gray60;
						}
						&::-webkit-input-placeholder { //Chrome/Opera/Safari
							color: @gray60;
						}
					}
					.btn {
						color: @white;
						margin: 20px 0 0;
						&.link {
							text-decoration: underline;
							&.lb-closer {
								display: block;
								color: @gray47;
								height: auto;
								font-size: 1em;
								font-weight: 400;
								margin-top: 10px;
								padding: 10px;
							}
						}
						&.lb-submit {
							border: none;
							background-color: @theme-blue;
							text-shadow: 1px 1px 1px darken(@theme-blue, 10%);
							border-bottom: 6px solid darken(@theme-blue, 20%);
						}
					}
				}
			}
		}
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.lb-wrapper {
		&.lb-rejectionModal {
			.lb-body-wrapper  {
				width: 360px;
				.lb-body {
					.info-wrapper {
						.read-carefully {
							font-size: 20px;
						}
						.btn {
							&.link {
								&:hover, &:focus {
									text-decoration: none;
								}
							}
							&.lb-submit {
								&:hover, &:focus {
									background-color: lighten(@theme-blue, 5%);
								}
								&:active {
									background-color: darken(@theme-blue, 5%);
								}
							}
						}
					}
				}
			}
		}
	}
}
