.accord-wrapper {
	.accord-action {
		color: inherit;
		display: block;
		position: relative;
		text-decoration: none;
		box-sizing: border-box;
		&:after {
			top: 0;
			right: 0;
			position: absolute;
			.font-icon('\f067');
		}
		&.active {
			&:after {
				.font-icon('\f068')
			}
		}
	}
	.accord-content {
		display: none;
	}
}
