.field {
	&.input-error {
		.error-message {
				padding: 5px;
				border-width: 1;
				border-radius: 3px;
				border-style: solid;
				box-sizing: border-box;
				.error-input-syling(false);
		}
		input {
			&[type="url"],
			&[type="text"],
			&[type="email"],
			&[type="phone"],
			&[type="search"],
			&[type="number"],
			&[type="password"] {
				.error-input-syling();
			}
		}
		textarea {
			.error-input-syling();
		}
		.select-wrapper {
			select {
				.error-input-syling();
			}
		}
	}
}

div.input-error {
	padding: 5px;
	border-width: 1px;
	border-radius: 3px;
	border-style: solid;
	box-sizing: border-box;
	.error-input-syling(false);
}

.error-input-syling(@useHover:true) {
	color: @gray29;
	background-color: @rosybrown1;
	border-color: darken(@rosybrown1, 15%);
	& when (@useHover = true) {
		&:hover {
			color: @gray29;
			background-color: @rosybrown1;
			border-color: darken(@rosybrown1, 15%);
			box-shadow: inset 0 0 2px darken(@rosybrown1, 15%);
		}
		&:active, &:focus {
			color: @gray29;
			background-color: @rosybrown1;
			border-color: darken(@rosybrown1, 15%);
			box-shadow: inset 0 0 2px darken(@rosybrown1, 15%);
		}
	}
}
