.error-page {
	header {
		.header-top {
			.nav-menu-opener {
				display: none;
			}
		}
	}
	.content-wrapper {
		width: 95%;
		margin: 0 auto;
		max-width: 1200px;
	}
}
