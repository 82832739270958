.col {
	display: inline-block;
}

.col-full,
.col-span1,
.col-span2,
.col-span3,
.col-span4,
.col-span5,
.col-span6,
.col-span7,
.col-span8,
.col-span9,
.col-span10,
.col-span11,
.col-span12 {
	width: 100%;
}

// 98.68
.col-pmspan1 { .col-width(1, 97.4); }
.col-pmspan2 { .col-width(2, 97.4); }
.col-pmspan3 { .col-width(3, 97.4); }
.col-pmspan4 { .col-width(4, 97.4); }
.col-pmspan5 { .col-width(5, 97.4); }
.col-pmspan6 { .col-width(6, 97.4); }
.col-pmspan7 { .col-width(7, 97.4); }
.col-pmspan8 { .col-width(8, 97.4); }
.col-pmspan9 { .col-width(9, 97.4); }
.col-pmspan10 { .col-width(10, 97.4); }
.col-pmspan11 { .col-width(11, 97.4); }


/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	// 98.9
	.col-span1, .col-pmspan1 { .col-width(1, 97.4); }
	.col-span2, .col-pmspan2 { .col-width(2, 97.4); }
	.col-span3, .col-pmspan3 { .col-width(3, 97.4); }
	.col-span4, .col-pmspan4 { .col-width(4, 97.4); }
	.col-span5, .col-pmspan5 { .col-width(5, 97.4); }
	.col-span6, .col-pmspan6 { .col-width(6, 97.4); }
	.col-span7, .col-pmspan7 { .col-width(7, 97.4); }
	.col-span8, .col-pmspan8 { .col-width(8, 97.4); }
	.col-span9, .col-pmspan9 { .col-width(9, 97.4); }
	.col-span10, .col-pmspan10 { .col-width(10, 97.4); }
	.col-span11, .col-pmspan11 { .col-width(11, 97.4); }
}
