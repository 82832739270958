.lb-blocker {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.opacity(50);
	z-index: 1000;
	display: none;
	position: fixed;
	background-color: @black;
}

.lb-wrapper {
	.lb-opener {
		& > * {
			cursor: pointer;
		}
	}
	.lb-body-wrapper {
		width: auto;
		height: auto;
		display: none;
		z-index: 1001;
		overflow: auto;
		max-width: 95%;
		max-height: 90%;
		position: fixed;
		background-color: @white;
		border: 1px solid @gray29;
		box-shadow: 0 0 15px 2px @gray29;
		.csstransforms & {
			.center();
		}
		.no-csstransforms & {
			.center-notransform();
		}
		.lb-close-bar {
			padding: 10px 15px 0 0 ;
			text-align: right;
			i {
				cursor: pointer;
			}
		}
		.lb-body {
			width: 100%;
			height: 100%;
			padding: 15px;
			display: block;
			box-sizing: border-box;
		}
	}
}
