.autocomplete-menu-wrapper {
	left: 0;
	top: 98%;
	z-index: 1;
	width: 100%;
	padding: 0 4px;
	position: absolute;
	box-sizing: border-box;
	.autocomplete-menu {
		margin: 0;
		padding: 0;
		width: 100%;
		height: auto;
		overflow: auto;
		list-style: none;
		max-height: 300px;
		overflow-x: hidden;
		background-color: @white;
		box-shadow: -1px 5px 5px @gray60;
		border: 1px solid darken(@gainsboro, 20%);
		.autocomplete-item {
			margin: 0;
			padding: 8px;
			display: block;
			cursor: pointer;
			box-sizing: border-box;
			transition: all .25s linear;
			border: 1px solid transparent;
			&:hover, &:focus, &.highlight {
				background-color: lighten(@dodgerblue, 20%);
				border-color: @dodgerblue;
			}
		}
	}
}
