/**
 * Div Table
 */
.perm-tbl-div {
	width: 100%;
	display: table;
	.tbl-row {
		display: table-row;
		padding: 0;
		.tbl-col {
			display: table-cell;
			vertical-align: middle;
			padding: 5px;
		}
		.tbl-head {
			font-weight: bold;
			display: table-cell;
		}
	}
}

.tbl-div {
	display: table;
	.first {
		padding-left: 20px;
	}
	.last {
		padding-right: 20px;
	}
	.tbl-row {
		padding: 10px;
		display: table-row;
		border-bottom: 1px solid darken(@lightgray, 30%);
		&:first-child {
			padding: 0;
		}
		&.even {
			background-color: lighten(@lightgray, 10%);
		}
		&.odd {
			background-color: lighten(@lightgray, 3%);
		}
		&.search-and-filters {
			&.hidden {
				display: none;
			}
		}
		.tbl-col {
			display: table-cell;
			vertical-align: middle;
			padding: 4px;
		}
		.tbl-search {
			padding: 5px;
			display: table-cell;
			vertical-align: middle;
			background-color: @darknavy;
		}
		.toggle-searchbar {
			cursor: pointer;
			&:after {
				.font-icon('\f0b0', 0);
			}
		}
		.tbl-head {
			display: none;
			font-weight: bold;
			vertical-align: middle;
			&[data-attr] {
				cursor: pointer;
				&:after {
					.font-icon('\f0dc', '5px', 'left');
				}
			}
			&.asc {
				&:after {
					.font-icon('\f0de', '5px', 'left');
				}
			}
			&.desc {
				&:after {
					.font-icon('\f0dd', '5px', 'left');
				}
			}
		}
	}
}

.data-tbl {
	.tbl-row {
		transition: background-color .25s linear;
		.tbl-col {
			transition: background-color .25s linear;
		}
	}
}


/**
 * small tablet
 */
@media only screen and (min-width : @sm-tablet-width) {
	.tbl-div {
		&.data-tbl {
			.tbl-row {
				&:hover, &:focus {
					cursor: default;
				}
				&[data-row-action] {
					&:hover, &:focus {
						cursor: pointer;
						background-color: darken(@aliceblue, 10%);
						.tbl-col {
							border-bottom-color: darken(@aliceblue, 30%);
						}
					}
				}
			}
		}
	}
}


/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.tbl-div {
		width: 100%;
		display: table;
		border-collapse: collapse;
		.tbl-row {
			padding: 0;
			display: table-row;
			border-bottom: none;
			.tbl-col {
				padding: 5px;
				display: table-cell;
				border-bottom: 1px solid darken(@lightgray, 30%);
			}
			.tbl-head {
				padding: 5px;
				color: @white;
				display: table-cell;
				background-color: @darknavy;
			}
		}
	}
}
