/**
 * colors
 */
/**
 * Main components to be used
 */
/**
 * Device sizes
 */
/**
 * Defaults for tags
 */
* {
  word-wrap: break-word;
}
body {
  font-size: 16px;
  line-height: normal;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
a {
  color: #4876ff;
  text-decoration: underline;
}
a:hover,
a:focus {
  text-decoration: none;
}
.clearfix {
  clear: both;
  float: none;
  display: block;
}
.text-hide {
  float: left;
  display: block;
  text-indent: -9999px;
}
.vmid {
  vertical-align: middle;
}
.hidden {
  display: none;
}
.site-width {
  width: 98%;
  max-width: 1200px;
}
.mobile,
.mobile-tablet {
  display: block !important;
}
.mobile-tbl,
.mobile-tablet-tbl {
  display: table-cell !important;
}
.tablet,
.desktop,
.tablet-tbl,
.desktop-tbl,
.desktop-tablet,
.desktop-tablet-tbl {
  display: none !important;
}
.lfloat,
.rfloat {
  width: 100%;
}
.p-lfloat {
  float: left;
}
.p-rfloat {
  float: right;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .tablet,
  .mobile-tablet,
  .desktop-tablet {
    display: block !important;
  }
  .tablet-tbl,
  .mobile-tablet-tbl,
  .desktop-tablet-tbl {
    display: table-cell !important;
  }
  .mobile,
  .desktop,
  .mobile-tbl,
  .desktop-tbl {
    display: none !important;
  }
  .lfloat {
    width: auto;
    float: left;
  }
  .rfloat {
    width: auto;
    float: right;
  }
}
/**
 * small desktop
 */
@media only screen and (min-width: 961px) {
  .desktop,
  .desktop-tablet {
    display: block !important;
  }
  .desktop-tbl .desktop-tablet-tbl {
    display: table-cell !important;
  }
  .mobile,
  .tablet,
  .mobile-tbl,
  .tablet-tbl,
  .mobile-tablet,
  .mobile-tablet-tbl {
    display: none !important;
  }
}
.col {
  display: inline-block;
}
.col-full,
.col-span1,
.col-span2,
.col-span3,
.col-span4,
.col-span5,
.col-span6,
.col-span7,
.col-span8,
.col-span9,
.col-span10,
.col-span11,
.col-span12 {
  width: 100%;
}
.col-pmspan1 {
  width: calc((97.4/12)*1*1%) !important;
}
.col-pmspan2 {
  width: calc((97.4/12)*2*1%) !important;
}
.col-pmspan3 {
  width: calc((97.4/12)*3*1%) !important;
}
.col-pmspan4 {
  width: calc((97.4/12)*4*1%) !important;
}
.col-pmspan5 {
  width: calc((97.4/12)*5*1%) !important;
}
.col-pmspan6 {
  width: calc((97.4/12)*6*1%) !important;
}
.col-pmspan7 {
  width: calc((97.4/12)*7*1%) !important;
}
.col-pmspan8 {
  width: calc((97.4/12)*8*1%) !important;
}
.col-pmspan9 {
  width: calc((97.4/12)*9*1%) !important;
}
.col-pmspan10 {
  width: calc((97.4/12)*10*1%) !important;
}
.col-pmspan11 {
  width: calc((97.4/12)*11*1%) !important;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .col-span1,
  .col-pmspan1 {
    width: calc((97.4/12)*1*1%) !important;
  }
  .col-span2,
  .col-pmspan2 {
    width: calc((97.4/12)*2*1%) !important;
  }
  .col-span3,
  .col-pmspan3 {
    width: calc((97.4/12)*3*1%) !important;
  }
  .col-span4,
  .col-pmspan4 {
    width: calc((97.4/12)*4*1%) !important;
  }
  .col-span5,
  .col-pmspan5 {
    width: calc((97.4/12)*5*1%) !important;
  }
  .col-span6,
  .col-pmspan6 {
    width: calc((97.4/12)*6*1%) !important;
  }
  .col-span7,
  .col-pmspan7 {
    width: calc((97.4/12)*7*1%) !important;
  }
  .col-span8,
  .col-pmspan8 {
    width: calc((97.4/12)*8*1%) !important;
  }
  .col-span9,
  .col-pmspan9 {
    width: calc((97.4/12)*9*1%) !important;
  }
  .col-span10,
  .col-pmspan10 {
    width: calc((97.4/12)*10*1%) !important;
  }
  .col-span11,
  .col-pmspan11 {
    width: calc((97.4/12)*11*1%) !important;
  }
}
.accord-wrapper .accord-action {
  color: inherit;
  display: block;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
}
.accord-wrapper .accord-action:after {
  top: 0;
  right: 0;
  position: absolute;
  content: '\f067';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.accord-wrapper .accord-action.active:after {
  content: '\f068';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.accord-wrapper .accord-content {
  display: none;
}
.lb-blocker {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: "alpha(opacity=50)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  opacity: 0.5;
  z-index: 1000;
  display: none;
  position: fixed;
  background-color: #000000;
}
.lb-wrapper .lb-opener > * {
  cursor: pointer;
}
.lb-wrapper .lb-body-wrapper {
  width: auto;
  height: auto;
  display: none;
  z-index: 1001;
  overflow: auto;
  max-width: 95%;
  max-height: 90%;
  position: fixed;
  background-color: #ffffff;
  border: 1px solid #4a4a4a;
  box-shadow: 0 0 15px 2px #4a4a4a;
}
.csstransforms .lb-wrapper .lb-body-wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-csstransforms .lb-wrapper .lb-body-wrapper {
  top: 50%;
  left: 50%;
  margin: 0;
  margin-top: -200px;
  margin-left: -250px;
}
.lb-wrapper .lb-body-wrapper .lb-close-bar {
  padding: 10px 15px 0 0 ;
  text-align: right;
}
.lb-wrapper .lb-body-wrapper .lb-close-bar i {
  cursor: pointer;
}
.lb-wrapper .lb-body-wrapper .lb-body {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: block;
  box-sizing: border-box;
}
.pagination-bar {
  margin: 10px 0;
}
.pagination-bar:after {
  content: '';
  clear: both;
  float: none;
  display: block;
}
.pagination-bar .pagination-info {
  padding: 5px;
  font-size: 14px;
}
.pagination-bar .pagination-info span {
  padding: 0 10px;
}
.pagination-bar .pagination-info span:first-child {
  padding-left: 0;
}
.pagination-bar .pagination-info span:last-child {
  padding-right: 0;
}
.pagination-bar .next-page,
.pagination-bar .prev-page,
.pagination-bar .page-number {
  float: left;
  color: #4a4a4a;
  overflow: hidden;
  padding: 4px 14px;
  text-decoration: none;
  background-color: #ededed;
  transition: all 0.2s linear;
  border: 1px solid #c7c7c7;
}
.pagination-bar .next-page:hover,
.pagination-bar .prev-page:hover,
.pagination-bar .page-number:hover,
.pagination-bar .next-page:focus,
.pagination-bar .prev-page:focus,
.pagination-bar .page-number:focus {
  transform: scale(1.05);
  background-color: #fafafa;
}
.pagination-bar .next-page:active,
.pagination-bar .prev-page:active,
.pagination-bar .page-number:active {
  background-color: #e0e0e0;
}
.pagination-bar .next-page.disabled,
.pagination-bar .prev-page.disabled,
.pagination-bar .page-number.disabled {
  cursor: default;
  color: #bababa;
}
.pagination-bar .next-page.disabled:hover,
.pagination-bar .prev-page.disabled:hover,
.pagination-bar .page-number.disabled:hover,
.pagination-bar .next-page.disabled:focus,
.pagination-bar .prev-page.disabled:focus,
.pagination-bar .page-number.disabled:focus {
  transform: none;
  background-color: #ededed;
}
.pagination-bar .next-page.current,
.pagination-bar .prev-page.current,
.pagination-bar .page-number.current {
  color: #ffffff;
  font-weight: bold;
  background-color: #1e90ff;
  border-color: #006ad1;
}
.pagination-bar .next-page.current:hover,
.pagination-bar .prev-page.current:hover,
.pagination-bar .page-number.current:hover,
.pagination-bar .next-page.current:focus,
.pagination-bar .prev-page.current:focus,
.pagination-bar .page-number.current:focus {
  transform: none;
  background-color: #1e90ff;
}
.pagination-bar .next-page {
  border-radius: 0 5px 5px 0;
}
.pagination-bar .prev-page {
  border-radius: 5px 0 0 5px;
}
.autocomplete-menu-wrapper {
  left: 0;
  top: 98%;
  z-index: 1;
  width: 100%;
  padding: 0 4px;
  position: absolute;
  box-sizing: border-box;
}
.autocomplete-menu-wrapper .autocomplete-menu {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  overflow: auto;
  list-style: none;
  max-height: 300px;
  overflow-x: hidden;
  background-color: #ffffff;
  box-shadow: -1px 5px 5px #999999;
  border: 1px solid #a9a9a9;
}
.autocomplete-menu-wrapper .autocomplete-menu .autocomplete-item {
  margin: 0;
  padding: 8px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.25s linear;
  border: 1px solid transparent;
}
.autocomplete-menu-wrapper .autocomplete-menu .autocomplete-item:hover,
.autocomplete-menu-wrapper .autocomplete-menu .autocomplete-item:focus,
.autocomplete-menu-wrapper .autocomplete-menu .autocomplete-item.highlight {
  background-color: #84c2ff;
  border-color: #1e90ff;
}
/**
 * Div Table
 */
.perm-tbl-div {
  width: 100%;
  display: table;
}
.perm-tbl-div .tbl-row {
  display: table-row;
  padding: 0;
}
.perm-tbl-div .tbl-row .tbl-col {
  display: table-cell;
  vertical-align: middle;
  padding: 5px;
}
.perm-tbl-div .tbl-row .tbl-head {
  font-weight: bold;
  display: table-cell;
}
.tbl-div {
  display: table;
}
.tbl-div .first {
  padding-left: 20px;
}
.tbl-div .last {
  padding-right: 20px;
}
.tbl-div .tbl-row {
  padding: 10px;
  display: table-row;
  border-bottom: 1px solid #868686;
}
.tbl-div .tbl-row:first-child {
  padding: 0;
}
.tbl-div .tbl-row.even {
  background-color: #ececec;
}
.tbl-div .tbl-row.odd {
  background-color: #dbdbdb;
}
.tbl-div .tbl-row.search-and-filters.hidden {
  display: none;
}
.tbl-div .tbl-row .tbl-col {
  display: table-cell;
  vertical-align: middle;
  padding: 4px;
}
.tbl-div .tbl-row .tbl-search {
  padding: 5px;
  display: table-cell;
  vertical-align: middle;
  background-color: #222b3a;
}
.tbl-div .tbl-row .toggle-searchbar {
  cursor: pointer;
}
.tbl-div .tbl-row .toggle-searchbar:after {
  content: '\f0b0';
  font-family: 'FontAwesome';
  margin-right: 0;
}
.tbl-div .tbl-row .tbl-head {
  display: none;
  font-weight: bold;
  vertical-align: middle;
}
.tbl-div .tbl-row .tbl-head[data-attr] {
  cursor: pointer;
}
.tbl-div .tbl-row .tbl-head[data-attr]:after {
  content: '\f0dc';
  font-family: 'FontAwesome';
  margin-left: 5px;
}
.tbl-div .tbl-row .tbl-head.asc:after {
  content: '\f0de';
  font-family: 'FontAwesome';
  margin-left: 5px;
}
.tbl-div .tbl-row .tbl-head.desc:after {
  content: '\f0dd';
  font-family: 'FontAwesome';
  margin-left: 5px;
}
.data-tbl .tbl-row {
  transition: background-color 0.25s linear;
}
.data-tbl .tbl-row .tbl-col {
  transition: background-color 0.25s linear;
}
/**
 * small tablet
 */
@media only screen and (min-width: 601px) {
  .tbl-div.data-tbl .tbl-row:hover,
  .tbl-div.data-tbl .tbl-row:focus {
    cursor: default;
  }
  .tbl-div.data-tbl .tbl-row[data-row-action]:hover,
  .tbl-div.data-tbl .tbl-row[data-row-action]:focus {
    cursor: pointer;
    background-color: #bde0ff;
  }
  .tbl-div.data-tbl .tbl-row[data-row-action]:hover .tbl-col,
  .tbl-div.data-tbl .tbl-row[data-row-action]:focus .tbl-col {
    border-bottom-color: #57b1ff;
  }
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .tbl-div {
    width: 100%;
    display: table;
    border-collapse: collapse;
  }
  .tbl-div .tbl-row {
    padding: 0;
    display: table-row;
    border-bottom: none;
  }
  .tbl-div .tbl-row .tbl-col {
    padding: 5px;
    display: table-cell;
    border-bottom: 1px solid #868686;
  }
  .tbl-div .tbl-row .tbl-head {
    padding: 5px;
    color: #ffffff;
    display: table-cell;
    background-color: #222b3a;
  }
}
.action-bar {
  padding: 5px;
  display: block;
  overflow: hidden;
  margin: 15px 0;
  box-sizing: border-box;
  background-color: #ededed;
  border: 1px solid #d4d4d4;
}
.action-bar.col {
  display: inline-block;
}
.btn-field {
  display: block;
  margin: 15px 0;
  overflow: hidden;
}
.btn-field.col {
  display: inline-block;
}
.btn {
  height: auto;
  outline: none;
  display: block;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.2s linear;
}
.btn.small {
  height: auto;
  font-size: 16px;
  padding: 5px 10px;
}
.btn.lfloat,
.btn.rfloat {
  margin: 6px 0;
}
.btn.submit,
.btn[type="submit"] {
  color: #ffffff;
  background-color: #ffa500;
  border: 1px solid #cc8400;
  text-shadow: 1px 1px 1px #cc8400;
}
.btn.submit:hover,
.btn[type="submit"]:hover,
.btn.submit:focus,
.btn[type="submit"]:focus {
  background-color: #ffb733;
}
.btn.submit:active,
.btn[type="submit"]:active {
  background-color: #e69500;
}
.btn.secondary {
  color: #ffffff;
  background-color: #4682b4;
  border: 1px solid #38678f;
  text-shadow: 1px 1px 1px #38678f;
}
.btn.secondary:hover,
.btn.secondary:focus {
  background-color: #699bc4;
}
.btn.secondary:active {
  background-color: #3f75a2;
}
.btn.additional {
  color: #4a4a4a;
  background-color: #d4d4d4;
  border: 1px solid #bbbbbb;
  text-shadow: none;
}
.btn.additional:hover,
.btn.additional:focus {
  background-color: #eeeeee;
}
.btn.additional:active {
  background-color: #c7c7c7;
}
.btn.delete {
  color: #ffffff;
  background-color: #b22222;
  border: 1px solid #871a1a;
  text-shadow: 1px 1px 1px #871a1a;
}
.btn.delete:hover,
.btn.delete:focus {
  background-color: #d73030;
}
.btn.delete:active {
  background-color: #9d1e1e;
}
.btn.approval {
  color: #ffffff;
  background-color: #228b22;
  border: 1px solid #186218;
  text-shadow: 1px 1px 1px #186218;
}
.btn.approval:hover,
.btn.approval:focus {
  background-color: #2cb42c;
}
.btn.approval:active {
  background-color: #1d771d;
}
.btn.disabled,
.btn[disabled] {
  background-color: #d3d3d3 !important;
  border: 1px solid #bababa !important;
  text-shadow: 1px 1px 1px #bababa !important;
  cursor: not-allowed;
}
.btn.disabled:hover,
.btn[disabled]:hover,
.btn.disabled:focus,
.btn[disabled]:focus {
  background-color: #ececec !important;
}
.btn.disabled:active,
.btn[disabled]:active {
  background-color: #c6c6c6 !important;
}
.btn.disabled:active,
.btn[disabled]:active,
.btn.disabled:focus,
.btn[disabled]:focus,
.btn.disabled:hover,
.btn[disabled]:hover {
  background-color: #d3d3d3 !important;
}
.btn .btn-icon {
  margin-right: 3px;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .btn.lfloat,
  .btn.rfloat {
    margin: 5px;
  }
}
form {
  margin: auto;
  max-width: 1200px;
}
form:after {
  content: '';
  clear: both;
  float: none;
  display: block;
}
:-moz-placeholder {
  color: #c0c0c0;
}
::-moz-placeholder {
  color: #c0c0c0;
}
:-ms-input-placeholder {
  color: #c0c0c0;
}
::-webkit-input-placeholder {
  color: #c0c0c0;
}
.field {
  margin: 10px 0;
  display: block;
  position: relative;
  box-sizing: border-box;
}
.field.col {
  padding: 0 4px;
  display: inline-block;
}
.field.split {
  width: 50%;
  margin: 5px;
  display: inline-block;
}
.field.thirds {
  margin: 5px;
  width: 33.33%;
  display: inline-block;
}
.field.quarters {
  width: 25%;
  margin: 5px;
  display: inline-block;
}
.field input[type="url"],
.field input[type="date"],
.field input[type="text"],
.field input[type="email"],
.field input[type="phone"],
.field input[type="search"],
.field input[type="number"],
.field input[type="password"] {
  height: 40px;
  outline: 0;
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s linear;
  border: 1px solid #a9a9a9;
}
.field input[type="url"]:hover,
.field input[type="date"]:hover,
.field input[type="text"]:hover,
.field input[type="email"]:hover,
.field input[type="phone"]:hover,
.field input[type="search"]:hover,
.field input[type="number"]:hover,
.field input[type="password"]:hover {
  border-color: #838383;
  box-shadow: inset 0 0 2px #b6b6b6;
}
.field input[type="url"]:focus,
.field input[type="date"]:focus,
.field input[type="text"]:focus,
.field input[type="email"]:focus,
.field input[type="phone"]:focus,
.field input[type="search"]:focus,
.field input[type="number"]:focus,
.field input[type="password"]:focus,
.field input[type="url"]:active,
.field input[type="date"]:active,
.field input[type="text"]:active,
.field input[type="email"]:active,
.field input[type="phone"]:active,
.field input[type="search"]:active,
.field input[type="number"]:active,
.field input[type="password"]:active {
  border: 1px solid #0077ea;
  box-shadow: inset 0 0 2px #0077ea;
}
.field input[type="url"][readonly],
.field input[type="date"][readonly],
.field input[type="text"][readonly],
.field input[type="email"][readonly],
.field input[type="phone"][readonly],
.field input[type="search"][readonly],
.field input[type="number"][readonly],
.field input[type="password"][readonly] {
  border: 1px solid #d4d4d4;
  background-color: #ededed;
}
.field input[type="url"][readonly]:hover,
.field input[type="date"][readonly]:hover,
.field input[type="text"][readonly]:hover,
.field input[type="email"][readonly]:hover,
.field input[type="phone"][readonly]:hover,
.field input[type="search"][readonly]:hover,
.field input[type="number"][readonly]:hover,
.field input[type="password"][readonly]:hover,
.field input[type="url"][readonly]:focus,
.field input[type="date"][readonly]:focus,
.field input[type="text"][readonly]:focus,
.field input[type="email"][readonly]:focus,
.field input[type="phone"][readonly]:focus,
.field input[type="search"][readonly]:focus,
.field input[type="number"][readonly]:focus,
.field input[type="password"][readonly]:focus,
.field input[type="url"][readonly]:active,
.field input[type="date"][readonly]:active,
.field input[type="text"][readonly]:active,
.field input[type="email"][readonly]:active,
.field input[type="phone"][readonly]:active,
.field input[type="search"][readonly]:active,
.field input[type="number"][readonly]:active,
.field input[type="password"][readonly]:active {
  outline: none;
  box-shadow: none;
  background-color: #ededed;
}
.field input[type="url"].sm,
.field input[type="date"].sm,
.field input[type="text"].sm,
.field input[type="email"].sm,
.field input[type="phone"].sm,
.field input[type="search"].sm,
.field input[type="number"].sm,
.field input[type="password"].sm {
  height: 30px;
  padding: 3px 6px;
}
.field input[type="date"] {
  display: inline-block;
  font-size: 11px;
  width: 115px !important;
}
.field textarea {
  height: auto;
  outline: 0;
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s linear;
  border: 1px solid #a9a9a9;
}
.field textarea:hover {
  border-color: #838383;
  box-shadow: inset 0 0 2px #b6b6b6;
}
.field textarea:focus,
.field textarea:active {
  border: 1px solid #0077ea;
  box-shadow: inset 0 0 2px #0077ea;
}
.field textarea[readonly="true"] {
  border: 1px solid #d4d4d4;
  background-color: #ededed;
}
.field textarea[readonly="true"]:hover,
.field textarea[readonly="true"]:focus,
.field textarea[readonly="true"]:active {
  outline: none;
  box-shadow: none;
  background-color: #ededed;
}
.field textarea[data-gramm] {
  transition: all 0.2s linear !important;
}
.field .select-wrapper {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.field .select-wrapper select {
  outline: 0;
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s linear;
  border: 1px solid #a9a9a9;
  height: 40px;
  appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #ffffff;
  padding: 6px 24px 6px 6px;
  vertical-align: middle;
}
.field .select-wrapper select:hover {
  border-color: #838383;
  box-shadow: inset 0 0 2px #b6b6b6;
}
.field .select-wrapper select:focus,
.field .select-wrapper select:active {
  border: 1px solid #0077ea;
  box-shadow: inset 0 0 2px #0077ea;
}
.field .select-wrapper select option {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.field .select-wrapper select::-ms-expand {
  display: none;
}
.field .select-wrapper:after {
  top: 12px;
  z-index: 9;
  right: 10px;
  filter: "alpha(opacity=50)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  opacity: 0.5;
  width: auto;
  height: auto;
  font-size: 18px;
  position: absolute;
  content: '\f0d7';
  font-family: 'FontAwesome';
  margin-right: 2px;
  pointer-events: none;
}
.ie9 .field .select-wrapper:before,
.ie8 .field .select-wrapper:before,
.ie7 .field .select-wrapper:before,
.lt-ie7 .field .select-wrapper:before {
  top: 2px;
  z-index: 7;
  right: 3px;
  width: 45px;
  content: '';
  height: 35px;
  position: absolute;
  background: #ffffff;
  pointer-events: none;
}
.field .select-wrapper.sm select {
  height: 30px;
  padding: 3px 24px 3px 6px;
}
.field .select-wrapper.sm:after {
  top: 6px;
}
.ie9 .field .select-wrapper.sm:before,
.ie8 .field .select-wrapper.sm:before,
.ie7 .field .select-wrapper.sm:before,
.lt-ie7 .field .select-wrapper.sm:before {
  height: 25px;
}
.field.checkbox-holder input[type='checkbox'].hidden + label.checkbox {
  cursor: pointer;
}
.field.checkbox-holder input[type='checkbox'].hidden + label.checkbox.right {
  text-align: right;
}
.field.checkbox-holder input[type='checkbox'].hidden + label.checkbox:before {
  color: #808080;
  font-size: 24px;
  cursor: pointer;
  vertical-align: middle;
  content: '\f096';
  font-family: 'FontAwesome';
  margin-right: 15px;
}
.field.checkbox-holder input[type='checkbox'].hidden + label.checkbox:before:hover {
  color: #000000;
}
.field.checkbox-holder input[type='checkbox'].hidden:checked + label.checkbox.right:before {
  right: -3.5px;
  content: '\f046';
  font-family: 'FontAwesome';
  margin-right: 15px;
}
.field.checkbox-holder input[type='checkbox'].hidden:checked + label.checkbox:before {
  margin-left: 1px;
  position: relative;
  color: #228b22;
  content: '\f046';
  font-family: 'FontAwesome';
  margin-right: 10.5px;
}
label {
  margin: 5px 0;
  display: inline-block;
}
label.required:before {
  margin: 0 2px;
  font-size: 8px;
  color: #b22222;
  content: '\f069';
  font-family: 'FontAwesome';
  margin-right: 2px;
  vertical-align: super;
}
.form-field-styling {
  outline: 0;
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s linear;
  border: 1px solid #a9a9a9;
}
.form-field-styling:hover {
  border-color: #838383;
  box-shadow: inset 0 0 2px #b6b6b6;
}
.form-field-styling:focus,
.form-field-styling:active {
  border: 1px solid #0077ea;
  box-shadow: inset 0 0 2px #0077ea;
}
.form-field-disabled-styling {
  border: 1px solid #d4d4d4;
  background-color: #ededed;
}
.form-field-disabled-styling:hover,
.form-field-disabled-styling:focus,
.form-field-disabled-styling:active {
  outline: none;
  box-shadow: none;
  background-color: #ededed;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  input[type="submit"] {
    width: auto;
    float: right;
  }
}
.field.input-error .error-message {
  padding: 5px;
  border-width: 1;
  border-radius: 3px;
  border-style: solid;
  box-sizing: border-box;
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.field.input-error input[type="url"],
.field.input-error input[type="text"],
.field.input-error input[type="email"],
.field.input-error input[type="phone"],
.field.input-error input[type="search"],
.field.input-error input[type="number"],
.field.input-error input[type="password"] {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.field.input-error input[type="url"]:hover,
.field.input-error input[type="text"]:hover,
.field.input-error input[type="email"]:hover,
.field.input-error input[type="phone"]:hover,
.field.input-error input[type="search"]:hover,
.field.input-error input[type="number"]:hover,
.field.input-error input[type="password"]:hover {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error input[type="url"]:active,
.field.input-error input[type="text"]:active,
.field.input-error input[type="email"]:active,
.field.input-error input[type="phone"]:active,
.field.input-error input[type="search"]:active,
.field.input-error input[type="number"]:active,
.field.input-error input[type="password"]:active,
.field.input-error input[type="url"]:focus,
.field.input-error input[type="text"]:focus,
.field.input-error input[type="email"]:focus,
.field.input-error input[type="phone"]:focus,
.field.input-error input[type="search"]:focus,
.field.input-error input[type="number"]:focus,
.field.input-error input[type="password"]:focus {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error textarea {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.field.input-error textarea:hover {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error textarea:active,
.field.input-error textarea:focus {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error .select-wrapper select {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.field.input-error .select-wrapper select:hover {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error .select-wrapper select:active,
.field.input-error .select-wrapper select:focus {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
div.input-error {
  padding: 5px;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
  box-sizing: border-box;
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.spinner-holder {
  position: relative!important;
}
.spinner-holder .spinner {
  position: absolute;
}
.csstransforms .spinner-holder .spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-csstransforms .spinner-holder .spinner {
  top: 50%;
  left: 50%;
  margin: 0;
  margin-top: -200px;
  margin-left: -250px;
}
.order-activity .tbl-div {
  font-size: 14px;
}
.order-activity .tbl-div .tbl-row .tbl-col.index {
  float: left;
  margin-right: 5px;
  font-weight: 700;
}
.order-activity .tbl-div .tbl-row .tbl-col.date {
  margin: 0 0 8px;
  font-weight: 700;
  text-align: right;
  border-bottom: 1px solid #bababa;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .order-activity .tbl-div .tbl-row .tbl-col.index {
    float: none;
    margin-right: 0;
    font-weight: 400;
  }
  .order-activity .tbl-div .tbl-row .tbl-col.date {
    margin: 0;
    font-weight: 400;
    text-align: left;
    border-bottom: 1px solid #868686;
  }
  .order-activity .tbl-div.data-tbl .tbl-row:hover .tbl-col.date {
    border-bottom-color: #57b1ff;
  }
}
.order-comments .tbl-div {
  font-size: 14px;
}
.order-comments .tbl-div .tbl-row .tbl-col.index {
  float: left;
  margin-right: 5px;
  font-weight: 700;
}
.order-comments .tbl-div .tbl-row .tbl-col.date {
  margin: 0 0 8px;
  font-weight: 700;
  text-align: right;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .order-comments .tbl-div .tbl-row .tbl-col.index {
    float: none;
    margin-right: 0;
    font-weight: 400;
  }
  .order-comments .tbl-div .tbl-row .tbl-col.date {
    margin: 0;
    font-weight: 400;
    text-align: left;
  }
}
.errors,
.success,
.warnings,
.error-msg,
.warning-msg,
.success-msg {
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}
.errors p,
.success p,
.warnings p,
.error-msg p,
.warning-msg p,
.success-msg p {
  margin: 2px 0;
}
.errors .msg-icon,
.success .msg-icon,
.warnings .msg-icon,
.error-msg .msg-icon,
.warning-msg .msg-icon,
.success-msg .msg-icon {
  width: 20px;
  text-align: center;
  vertical-align: middle;
}
.errors .msg-info,
.success .msg-info,
.warnings .msg-info,
.error-msg .msg-info,
.warning-msg .msg-info,
.success-msg .msg-info {
  padding-left: 10px;
}
.errors,
.error-msg {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border: 1px solid #ff7474;
}
.errors .msg-icon:before,
.error-msg .msg-icon:before {
  font-size: 20px;
  color: #8b1a1a;
  content: '\f06a';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.warnings,
.warning-msg {
  color: #4a4a4a;
  background-color: #ffe7ba;
  border: 1px solid #ffcc6e;
}
.warnings .msg-icon:before,
.warning-msg .msg-icon:before {
  font-size: 20px;
  color: #ed9121;
  content: '\f071';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.success,
.success-msg {
  color: #4a4a4a;
  background-color: #bdfcc9;
  border: 1px solid #74f98d;
}
.success .msg-icon:before,
.success-msg .msg-icon:before {
  font-size: 20px;
  color: #228b22;
  content: '\f058';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.breadcrumbs {
  margin: 15px 0 ;
  font-size: 13px;
}
header {
  z-index: 1;
  padding: 10px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 4px 5px -2px #4a4a4a;
}
header .nav-menu-opener {
  margin: 0;
  float: right;
  line-height: 1;
  display: block;
  cursor: pointer;
  padding: 5px 14px;
  text-align: center;
  color: #1e90ff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
header .nav-menu-opener span {
  line-height: 1;
  display: block;
  font-size: 12px;
}
header .nav-menu-opener span:before {
  line-height: 1;
  display: block;
  font-size: 30px;
  content: '\f0c9';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  header {
    padding: 0;
  }
  header .logo-wrapper {
    padding: 19px 20px;
    padding-right: 0;
  }
  header .nav-menu-opener {
    padding: 15px 40px;
    transition: all 0.25s linear;
  }
  header .nav-menu-opener span {
    font-size: 16px;
  }
  header .nav-menu-opener span:before {
    font-size: 40px;
  }
  header .nav-menu-opener:hover {
    color: #ffffff;
    background-color: #005db7;
  }
}
nav.nav-menu {
  top: 0;
  right: 0;
  width: 90%;
  z-index: -1;
  height: 100%;
  display: none;
  position: fixed;
  background-color: #ededed;
}
#menu_toggle_view_state:checked ~ nav.nav-menu,
#menu_toggle_view_state:checked + .page-slide-wrapper + nav.nav-menu {
  display: block;
}
nav.nav-menu .nav-header {
  height: '70px';
  padding: 6px 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #c0c0c0;
}
nav.nav-menu .nav-header .user-info {
  width: 100%;
  color: #1e90ff;
  padding-right: 52px;
  box-sizing: border-box;
}
nav.nav-menu .nav-header .user-info span {
  display: block;
  line-height: 1.1;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
nav.nav-menu .nav-header .user-info span:before {
  display: block;
  font-size: 40px;
  content: '\f2be';
  font-family: 'FontAwesome';
  margin-right: 5px;
}
nav.nav-menu .nav-header .nav-menu-closer {
  top: 10px;
  margin: 0;
  right: 10px;
  padding: 0 10px;
  cursor: pointer;
  text-align: center;
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
nav.nav-menu .nav-header .nav-menu-closer span {
  display: block;
  line-height: 1;
  font-size: 12px;
}
nav.nav-menu .nav-header .nav-menu-closer span:before {
  display: block;
  font-size: 40px;
  content: '\f00d';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
nav.nav-menu .nav-list-wrapper {
  display: block;
  height: calc(100% - (70px + 47px + 3px));
  overflow: hidden;
}
nav.nav-menu .nav-list-wrapper .nav-list {
  margin: 0;
  padding: 0;
  color: #4a4a4a;
  list-style: none;
}
nav.nav-menu .nav-list-wrapper .nav-list li {
  border-bottom: 1px solid #c0c0c0;
}
nav.nav-menu .nav-list-wrapper .nav-list li .accord-action {
  height: 55px;
  color: #787878;
  font-size: 20px;
  font-weight: 500;
  padding: 15px 10px;
}
nav.nav-menu .nav-list-wrapper .nav-list li .accord-action:after {
  top: 16px;
  right: 16px;
  font-size: 25px;
}
nav.nav-menu .nav-list-wrapper .nav-list li .accord-action.active {
  color: #000000;
  border-bottom: 2px solid #c0c0c0;
}
nav.nav-menu .nav-list-wrapper .nav-list li .accord-content {
  height: auto;
  overflow: auto;
  max-height: 300px;
  max-height: calc(100vh - (75px + 45px + 1px + (55px * 3)));
  background-color: #c0c0c0;
}
nav.nav-menu .nav-list-wrapper .nav-list li .accord-content h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 10px;
  background-color: #c0c0c0;
}
nav.nav-menu .nav-list-wrapper .nav-list li .accord-content .proof-viewers i {
  color: #25cea2;
}
nav.nav-menu .nav-list-wrapper .nav-list li .accord-content .proof-viewers i.rejected {
  color: #ff0067;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p {
  margin: 0;
  color: #000000;
  display: block;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 5px;
  text-decoration: none;
  box-sizing: border-box;
  border-bottom: 1px solid #aeaeae;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a.odd,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p.odd {
  background-color: #d4d4d4;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a.even,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p.even {
  background-color: #c2c2c2;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a .status,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p .status {
  color: #1e90ff;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a .viewing,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p .viewing {
  color: #787878;
  font-size: 12px;
  font-weight: 400;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a:before,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p:before {
  width: 20px;
  content: '';
  font-family: 'FontAwesome';
  margin-right: 5px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  text-shadow: 1px 1px 1px #999999;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a.rejected .status,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p.rejected .status {
  color: #ff0067;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a.rejected:before,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p.rejected:before {
  color: #ff0067;
  content: '\f023';
  font-family: 'FontAwesome';
  margin-right: 5px;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a.approved .status,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p.approved .status {
  color: #25cea2;
}
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a.approved:before,
nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p.approved:before {
  color: #25cea2;
  content: '\f00c';
  font-family: 'FontAwesome';
  margin-right: 5px;
}
nav.nav-menu .nav-footer {
  left: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  height: '47px';
}
nav.nav-menu .nav-footer a,
nav.nav-menu .nav-footer label {
  margin: 0;
  padding: 10px;
  color: #787878;
  display: block;
  cursor: pointer;
  text-decoration: none;
  border-top: 1px solid #c0c0c0;
}
nav.nav-menu .nav-footer a:after,
nav.nav-menu .nav-footer label:after {
  right: 10px;
  position: absolute;
  content: '\f05a';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  nav.nav-menu {
    z-index: 2;
    width: 75%;
    right: -100%;
    display: block;
    background-color: #3b3b3b;
    transition: right 0.2s ease-out;
    box-shadow: 10px 0px 10px 10px #000000;
  }
  #menu_toggle_view_state:checked ~ nav.nav-menu,
  #menu_toggle_view_state:checked + .page-slide-wrapper + nav.nav-menu {
    right: 0;
  }
  nav.nav-menu .nav-header {
    padding: 0;
    height: '112px';
    background-color: #1e90ff;
  }
  nav.nav-menu .nav-header .user-info {
    color: #ffffff;
    padding: 15px 0;
    padding-right: 90px;
    text-align: center;
  }
  nav.nav-menu .nav-header .user-info span {
    font-size: 14px;
  }
  nav.nav-menu .nav-header .user-info span:before {
    font-size: 60px;
  }
  nav.nav-menu .nav-header .nav-menu-closer {
    top: 0;
    right: 0;
    padding: 19px 21px;
    color: #ffffff;
    transition: all 0.25s linear;
  }
  nav.nav-menu .nav-header .nav-menu-closer span {
    font-size: 14px;
  }
  nav.nav-menu .nav-header .nav-menu-closer span:before {
    font-size: 60px;
  }
  nav.nav-menu .nav-header .nav-menu-closer:hover,
  nav.nav-menu .nav-header .nav-menu-closer:focus {
    background-color: #005db7;
  }
  nav.nav-menu .nav-list-wrapper {
    display: block;
    height: calc(100% - (112px + 47px + 3px));
  }
  nav.nav-menu .nav-list-wrapper .nav-list {
    color: #aaaaaa;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li a.accord-action {
    color: #aaaaaa;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li a.accord-action.active {
    color: #ffffff;
    border-bottom-color: #ffffff;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-action {
    height: 65px;
    font-size: 25px;
    padding: 20px 35px;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-action:after {
    top: 25px;
    right: 35px;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content {
    max-height: 400px;
    max-height: calc(100vh - (117px + 45px + 1px + (65px * 3)));
    background-color: #3b3b3b;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 10px;
    color: #aaaaaa;
    background-color: #3b3b3b;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content p {
    margin: 0;
    padding: 5px 10px;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content .tbl-div .first {
    padding-left: 20px;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content .tbl-div .last {
    padding-right: 20px;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content .tbl-div .even {
    color: #ffffff;
    background-color: #3b3b3b;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content .tbl-div .odd {
    color: #ffffff;
    background-color: #272727;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content .tbl-div .tbl-head {
    color: #ffffff;
    background-color: #000000;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content .tbl-div .tbl-col {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li .accord-content .tbl-div .tbl-col.first {
    border-right: 1px solid #868686;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a,
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p {
    font-size: 18px;
    padding: 10px 30px;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a.odd,
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p.odd {
    color: #ffffff;
    background-color: #272727;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a.even,
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p.even {
    color: #ffffff;
    background-color: #3b3b3b;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a .viewing,
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p .viewing {
    font-size: 14px;
  }
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content a:before,
  nav.nav-menu .nav-list-wrapper .nav-list li.versions .accord-content p:before {
    text-shadow: 1px 1px 1px #3b3b3b;
  }
  nav.nav-menu .nav-footer {
    height: '47px';
  }
  nav.nav-menu .nav-footer a,
  nav.nav-menu .nav-footer label {
    padding: 12px;
    font-size: 18px;
    text-align: center;
    color: #aaaaaa;
    border-top: 1px solid #aaaaaa;
  }
  nav.nav-menu .nav-footer a:after,
  nav.nav-menu .nav-footer label:after {
    right: auto;
    font-size: 16px;
    margin-left: 10px;
    position: relative;
  }
}
.confirmation-info {
  text-align: center;
}
.confirmation-info h3 {
  font-size: 30px;
}
.confirmation-info h3 span {
  vertical-align: middle;
}
.confirmation-info h3:before {
  font-size: 50px;
  content: '\f058';
  font-family: 'FontAwesome';
  margin-right: 5px;
  vertical-align: middle;
}
.confirmation-info p {
  font-weight: 500;
}
.confirmation-info p.details {
  font-size: 18px;
}
.confirmation-info .btn {
  margin: 30px 0 0;
}
.confirmation-info .btn.submit {
  border: none;
  width: 150px;
  height: 46px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #808080;
  border: 1px solid #676767;
  text-shadow: 1px 1px 1px #676767;
  border-bottom: 6px solid #4d4d4d;
}
.confirmation-info .btn.submit:hover,
.confirmation-info .btn.submit:focus {
  background-color: #9a9a9a;
}
.confirmation-info .btn.submit:active {
  background-color: #737373;
}
.confirmation-info .order-info-small {
  margin: 20px 0 0;
}
.confirmation-info .order-info-small .info {
  margin: 0;
  font-size: 15px;
}
.confirmation-info .order-info-small .info + .sales-order,
.confirmation-info .order-info-small .info ~ .sales-order {
  font-size: 25px;
  text-transform: uppercase;
}
.confirmation-info .order-info-small .sales-order {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
}
.confirmation-info.rejected {
  color: #ff0067;
}
.confirmation-info.rejected h3 {
  color: #ff0067;
}
.confirmation-info.approved {
  color: #25cea2;
}
.confirmation-info.approved h3 {
  color: #25cea2;
}
.confirmation-info .wufoo-survey {
  width: 100%;
  padding: 10px;
  max-width: 620px;
  margin: 20px auto;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #ededed;
}
body.order-page,
.order-page.wrapper {
  background: url('/pub/images/base/pdf-bg-texture.png') scroll no-repeat center center #404040;
}
.order-header {
  padding: 10px;
  color: #ffffff;
  background-color: #1e90ff;
}
.order-header h1 {
  color: #ffffff;
}
.order-header a {
  color: #ffffff;
  text-decoration: none;
}
.order-header .download {
  display: none;
}
.order-header .order-info,
.order-header .proof-version {
  vertical-align: top;
  display: inline-block;
}
.order-header .order-info {
  width: 78%;
  margin-right: 5px;
  width: calc(100% - 70px);
}
.order-header .order-info p {
  font-size: 14px;
  margin: 0 0 5px;
}
.order-header .order-info h2 {
  margin: 0;
  font-size: 15px;
}
.order-header .order-info h2 + h1 {
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
}
.order-header .order-info h1 {
  margin: 0;
  font-size: 20px;
  word-break: break-all;
}
.order-header .proof-version {
  width: 54px;
  text-align: center;
}
.order-header .proof-version p {
  font-size: 14px;
  margin: 0 0 5px;
}
.order-header .proof-version p.version {
  margin: 0;
  font-size: 45px;
  font-weight: 700;
  line-height: 45px;
}
.status-bar {
  color: #ffffff;
  padding: 10px;
  font-size: 25px;
  font-weight: 300;
}
.status-bar.approved {
  background-color: #25cea2;
  border-top: 1px solid #91ebd4;
  text-shadow: 1px 1px 1px #146f57;
}
.status-bar.approved span:before {
  color: #228b22;
  content: '\f00c';
  font-family: 'FontAwesome';
  margin-right: 10px;
}
.status-bar.rejected {
  background-color: #ff0067;
  border-top: 1px solid #ff8ab9;
  text-shadow: 1px 1px 1px #8f003a;
}
.status-bar.rejected span:before {
  color: #b22222;
  content: '\f023';
  font-family: 'FontAwesome';
  margin-right: 10px;
}
.pdf-viewer-wrapper {
  z-index: 0;
  padding: 25% 50%;
  padding: calc((100% - 162px) / 2) 50%;
  padding: calc((100vh - 162px) / 2) 50%;
  position: relative;
  background: url('/pub/images/base/pdf-bg-texture.png') scroll no-repeat center center #404040;
}
.pdf-viewer-wrapper object,
.pdf-viewer-wrapper embed,
.pdf-viewer-wrapper iframe {
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.order-actions {
  text-align: center;
  padding: 30px 10px 15px;
  background-color: #4a4a4a;
}
.order-actions .min-maximizer {
  display: none;
}
.order-actions .flash-message {
  top: 0;
  left: 0;
  width: 100%;
  color: #ffffff;
  display: block;
  padding: 2px 0;
  text-align: center;
  position: absolute;
  box-sizing: border-box;
}
.order-actions .lb-wrapper {
  width: 120px;
  margin: 0 14px;
  display: inline-block;
}
.order-actions .lb-wrapper .btn {
  width: 100%;
  height: 60px;
  color: #ffffff;
  display: block;
  font-weight: 700;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 14px 16px 18px;
}
.order-actions .lb-wrapper .btn.reject {
  box-shadow: 0 4px 5px #212121;
  background-color: #aaaaaa;
  text-shadow: 1px 1px 1px #5e5e5e;
  border-bottom: 5px solid #5e5e5e;
}
.order-actions .lb-wrapper .btn.approve {
  box-shadow: 0 4px 5px #212121;
  background-color: #25cea2;
  text-shadow: 1px 1px 1px #198d6f;
  border-bottom: 5px solid #198d6f;
}
.js .order-actions {
  right: 0;
  z-index: 1;
  width: 100%;
  bottom: -105px;
  position: fixed;
  background-color: #000000;
  transition: right 0.2s ease-out;
  background-color: rgba(0, 0, 0, 0.5);
}
.js .order-actions .min-maximizer {
  left: 10px;
  width: 60px;
  height: 40px;
  bottom: 100%;
  color: #ffffff;
  display: block;
  padding: 2px 0;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  background-color: #1e90ff;
  text-shadow: 1px 1px 1px #0483ff;
}
.js .order-actions .min-maximizer:before {
  content: '\f0d8';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.js .order-actions.active .min-maximizer:before {
  content: '\f0d7';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.js #menu_toggle_view_state:checked + .page-slide-wrapper .order-actions,
.js #menu_toggle_view_state:checked ~ .page-slide-wrapper .order-actions {
  right: 90%;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .order-header {
    padding: 10px;
  }
  .order-header .order-info {
    width: auto;
    max-width: 50%;
    margin-right: 20px;
    padding: 0 20px;
    border-right: 1px solid #ffffff;
  }
  .order-header .order-info p {
    font-size: 14px;
    margin: 0 0 5px;
  }
  .order-header .order-info h2 {
    margin: 0;
    font-size: 20px;
  }
  .order-header .order-info h2 + h1 {
    font-size: 35px;
  }
  .order-header .order-info h1 {
    font-size: 25px;
  }
  .order-header .proof-version {
    position: relative;
    text-align: center;
  }
  .order-header .proof-version p {
    font-size: 14px;
    margin: 0 0 5px;
  }
  .order-header .proof-version p.version {
    margin: 0;
    font-size: 62px;
    font-weight: 700;
    line-height: 62px;
  }
  .order-header .proof-version:before {
    width: 1px;
    content: '';
    left: -25px;
    height: 100%;
    display: block;
    position: absolute;
    background: #ffffff;
  }
  .order-header .download {
    float: right;
    display: block;
    text-align: center;
  }
  .order-header .download a {
    filter: "alpha(opacity=100)";
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
    color: #ffffff;
    display: block;
    padding: 12px 18px;
    transition: all 0.25s linear;
  }
  .order-header .download a:before {
    display: block;
    font-size: 40px;
    content: '\f0ed';
    font-family: 'FontAwesome';
    margin-right: 2px;
  }
  .order-header .download a:hover,
  .order-header .download a:focus {
    filter: "alpha(opacity=80)";
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
  }
  .status-bar {
    padding: 10px 20px;
  }
  .pdf-viewer-wrapper {
    padding: 25% 50%;
    padding: calc((100% - 194px) / 2) 50%;
    padding: calc((100vh - 194px) / 2) 50%;
  }
  .order-actions .lb-wrapper {
    width: 160px;
  }
  .order-actions .lb-wrapper .btn.reject:hover,
  .order-actions .lb-wrapper .btn.reject:focus {
    background-color: #c3c3c3;
  }
  .order-actions .lb-wrapper .btn.reject:active {
    background-color: #919191;
  }
  .order-actions .lb-wrapper .btn.approve:hover,
  .order-actions .lb-wrapper .btn.approve:focus {
    background-color: #48deb7;
  }
  .order-actions .lb-wrapper .btn.approve:active {
    background-color: #1da380;
  }
  .js #menu_toggle_view_state:checked + .page-slide-wrapper .order-actions,
  .js #menu_toggle_view_state:checked ~ .page-slide-wrapper .order-actions {
    right: 0;
  }
}
/**
 * Theme Specific Colors
 */
body {
  background-color: #f5f5f5;
}
h1 {
  color: #333333;
}
.page-slide-wrapper {
  right: 0;
  position: relative;
  transition: right 0.2s ease-out;
  background-color: #f5f5f5;
}
#menu_toggle_view_state:checked + .page-slide-wrapper,
#menu_toggle_view_state:checked ~ .page-slide-wrapper {
  right: 90%;
  width: 100%;
  height: auto;
  position: fixed;
  box-shadow: 4px 0 5px -2px #787878;
}
.page-slide-blocker {
  top: 0;
  right: 0;
  height: 0;
  z-index: 2;
  width: 100%;
  filter: "alpha(opacity=50)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  opacity: 0.5;
  display: block;
  position: fixed;
  background-color: #000000;
  transition: right 0.2s ease-out;
}
#menu_toggle_view_state:checked + .page-slide-wrapper .page-slide-blocker,
#menu_toggle_view_state:checked ~ .page-slide-wrapper .page-slide-blocker {
  right: 90%;
  height: 100%;
}
.wrapper {
  z-index: 0;
  position: relative;
  background-color: #f5f5f5;
}
.text-center {
  text-align: center;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  #menu_toggle_view_state:checked + .page-slide-wrapper .page-slide-blocker,
  #menu_toggle_view_state:checked ~ .page-slide-wrapper .page-slide-blocker {
    right: 0;
    height: 100%;
  }
  #menu_toggle_view_state:checked + .page-slide-wrapper,
  #menu_toggle_view_state:checked ~ .page-slide-wrapper {
    right: 0;
    width: auto;
    box-shadow: none;
    position: relative;
  }
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper {
  width: 320px;
  height: auto;
  max-height: 600px;
  border-radius: 10px;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body {
  padding: 0;
  overflow: hidden;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .heading {
  margin: 0;
  color: #ffffff;
  padding: 10px 0;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  background-color: #25cea2;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper {
  height: 88%;
  height: calc(100% - (58px + 10px));
  padding: 0 20px 25px;
  box-sizing: border-box;
  overflow: auto;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .read-carefully {
  color: #4a4a4a;
  margin: 15px 0;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .message {
  font-size: 15px;
  margin: 15px auto 25px;
  text-align: left;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .acknowledgement-wrapper {
  padding: 10px;
  border-top: 1px solid #aaaaaa;
  cursor: pointer;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .acknowledgement-wrapper label {
  cursor: pointer;
  font-size: 12px;
  margin: 0;
  text-align: left;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .acknowledgement-wrapper input[type='checkbox'] {
  cursor: pointer;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .btn {
  color: #ffffff;
  margin: 10px 0 0;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .btn.link {
  text-decoration: underline;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .btn.link.lb-closer {
  color: #787878;
  display: block;
  height: auto;
  font-size: 1em;
  font-weight: 400;
  padding: 10px;
}
.lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-submit {
  background-color: #25cea2;
  border: none;
  border-bottom: 6px solid #15785e;
  margin-top: 5px;
  text-shadow: 1px 1px 1px #1da380;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .lb-wrapper.lb-approvalModal .lb-body-wrapper {
    width: 360px;
  }
  .lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .read-carefully {
    font-size: 20px;
  }
  .lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .btn.link:hover,
  .lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .btn.link:focus {
    text-decoration: none;
  }
  .lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-submit:hover,
  .lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-submit:focus {
    background-color: #32daae;
  }
  .lb-wrapper.lb-approvalModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-submit:active {
    background-color: #21b891;
  }
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper {
  width: 320px;
  height: auto ;
  max-height: 600px;
  border-radius: 10px;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body {
  padding: 0;
  overflow: hidden;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .heading {
  margin: 0;
  color: #ffffff;
  padding: 10px 0;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  background-color: #ff0067;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper {
  height: 88%;
  height: calc(100% - (58px + 10px));
  padding: 0 20px 25px;
  box-sizing: border-box;
  overflow: auto;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .read-carefully {
  color: #4a4a4a;
  margin: 15px 0;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .message {
  font-size: 15px;
  text-align: left;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper label {
  display: none;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper textarea {
  color: #ffffff;
  border: 1px solid #000000;
  background-color: #3b3b3b;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper textarea:-moz-placeholder {
  color: #999999;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper textarea::-moz-placeholder {
  color: #999999;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper textarea:-ms-input-placeholder {
  color: #999999;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper textarea::-webkit-input-placeholder {
  color: #999999;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .btn {
  color: #ffffff;
  margin: 20px 0 0;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .btn.link {
  text-decoration: underline;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .btn.link.lb-closer {
  display: block;
  color: #787878;
  height: auto;
  font-size: 1em;
  font-weight: 400;
  margin-top: 10px;
  padding: 10px;
}
.lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-submit {
  border: none;
  background-color: #1e90ff;
  text-shadow: 1px 1px 1px #0077ea;
  border-bottom: 6px solid #005db7;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .lb-wrapper.lb-rejectionModal .lb-body-wrapper {
    width: 360px;
  }
  .lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .read-carefully {
    font-size: 20px;
  }
  .lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .btn.link:hover,
  .lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .btn.link:focus {
    text-decoration: none;
  }
  .lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-submit:hover,
  .lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-submit:focus {
    background-color: #389dff;
  }
  .lb-wrapper.lb-rejectionModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-submit:active {
    background-color: #0483ff;
  }
}
.lb-wrapper.lb-tcModal .lb-body-wrapper {
  width: 320px;
  height: 600px;
  border-radius: 10px;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body {
  padding: 0;
  overflow: hidden;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .heading {
  margin: 0;
  color: #ffffff;
  padding: 10px 0;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  background-color: #1e90ff;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper {
  height: 88%;
  height: calc(100% - (58px + 10px));
  padding: 0 20px 20px;
  box-sizing: border-box;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .read-carefully {
  color: #ff0000;
  margin: 15px 0;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .callout-box {
  height: 78%;
  height: calc(100% - 124px);
  padding: 10px;
  overflow: auto;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: #ededed;
  border: 1px solid #e8e8e8;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .callout-box .important-header {
  color: #ff0000;
  margin: 35px 0 5px;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .callout-box .red {
  color: #ff0000;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .callout-box ul {
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 0 10px;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .callout-box ul li {
  padding: 0;
  margin: 0 0 10px 10px;
}
.lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-closer {
  color: #ffffff;
  margin: 20px 0 0;
  background-color: #1e90ff;
  text-shadow: 1px 1px 1px #0077ea;
  border-bottom: 6px solid #005db7;
}
.no-js .lb-wrapper.lb-tcModal .lb-body-wrapper {
  width: 100%;
  height: auto;
  display: block;
  max-width: none;
  max-height: none;
  box-shadow: none;
  border-radius: 0;
  position: relative;
  border: none;
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}
.no-js .lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .heading,
.no-js .lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-closer {
  display: none;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .lb-wrapper.lb-tcModal .lb-body-wrapper {
    width: 360px;
  }
  .lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-closer:hover,
  .lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-closer:focus {
    background-color: #389dff;
  }
  .lb-wrapper.lb-tcModal .lb-body-wrapper .lb-body .info-wrapper .btn.lb-closer:active {
    background-color: #0483ff;
  }
  .no-js .lb-wrapper.lb-tcModal .lb-body-wrapper {
    width: 100%;
  }
}
.error-page header .header-top .nav-menu-opener {
  display: none;
}
.error-page .content-wrapper {
  width: 95%;
  margin: 0 auto;
  max-width: 1200px;
}
