/**
 * Main components to be used
 */
@body-bg-color: @white-smoke;
@link-color: @blue;

/**
 * Device sizes
 */
@sm-mobile-width: 321px;
@mobile-width: 481px;
@sm-tablet-width: 601px;
@tablet-width: 769px;
@sm-desktop-width: 961px;
@desktop-width: 1201px;


/**
 * Defaults for tags
 */
* {
	word-wrap: break-word;
}

body {
	font-size: 16px;
	line-height: normal;
	font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

a {
	color: @royalblue1;
	text-decoration: underline;

	&:hover, &:focus {
		text-decoration: none;
	}
}

.clearfix {
	clear: both;
	float: none;
	display: block;
}

.text-hide {
	float: left;
	display: block;
	text-indent: -9999px;
}

.vmid {
	vertical-align: middle;
}

.hidden {
	display: none;
}

.site-width {
	width: 98%;
	max-width: 1200px;
}

.mobile,
.mobile-tablet {
	display: block !important;
}

.mobile-tbl,
.mobile-tablet-tbl {
	display: table-cell !important;
}

.tablet,
.desktop,
.tablet-tbl,
.desktop-tbl,
.desktop-tablet,
.desktop-tablet-tbl {
	display: none !important;
}

.lfloat,
.rfloat {
	width: 100%;
}

.p-lfloat {
	float: left;
}

.p-rfloat {
	float: right;
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.tablet,
	.mobile-tablet,
	.desktop-tablet {
		display: block !important;
	}

	.tablet-tbl,
	.mobile-tablet-tbl,
	.desktop-tablet-tbl {
		display: table-cell !important;
	}

	.mobile,
	.desktop,
	.mobile-tbl,
	.desktop-tbl {
		display: none !important;
	}


	.lfloat {
		width: auto;
		float: left;
	}

	.rfloat {
		width: auto;
		float: right;
	}
}

/**
 * small desktop
 */
@media only screen and (min-width : @sm-desktop-width) {
	.desktop,
	.desktop-tablet {
		display: block !important;
	}

	.desktop-tbl
	.desktop-tablet-tbl {
		display: table-cell !important;
	}

	.mobile,
	.tablet,
	.mobile-tbl,
	.tablet-tbl,
	.mobile-tablet,
	.mobile-tablet-tbl {
		display: none !important;
	}
}
