.lb-wrapper {
	&.lb-tcModal {
		.lb-body-wrapper  {
			width: 320px;
			height: 600px;
			border-radius: 10px;
			.lb-body {
				padding: 0;
				overflow: hidden;
				.heading {
					margin: 0;
					color: @white;
					padding: 10px 0;
					font-size: 32px;
					font-weight: 500;
					text-align: center;
					background-color: @theme-blue;
				}
				.info-wrapper {
					height: 88%;
					height: calc(100% - (58px + 10px));
					padding: 0 20px 20px;
					box-sizing: border-box;
					.read-carefully {
						color: @red;
						margin: 15px 0;
						font-size: 20px;
						font-weight: 500;
						text-align: center;
					}
					.callout-box {
						height: 78%;
						height: calc(100% - 124px);
						padding: 10px;
						overflow: auto;
						border-radius: 2px;
						box-sizing: border-box;
						background-color: @gray93;
						border: 1px solid darken(@gray93, 2%);
						.important-header {
							color: @red;
							margin: 35px 0 5px;
						}
						.red {
							color: @red;
						}
						ul {
							padding: 0;
							font-size: 15px;
							font-weight: 400;
							margin: 0 0 0 10px;
							li {
								padding: 0;
								margin: 0 0 10px 10px;
							}
						}
					}
					.btn.lb-closer {
						color: @white;
						margin: 20px 0 0;
						background-color: @theme-blue;
						text-shadow: 1px 1px 1px darken(@theme-blue, 10%);
						border-bottom: 6px solid darken(@theme-blue, 20%);
					}
				}
			}
			.no-js & {
				width: 100%;
				height: auto;
				display: block;
				max-width: none;
				max-height: none;
				box-shadow: none;
				border-radius: 0;
				position: relative;
				border: none;
				border-top: 2px solid @white;
				border-bottom: 2px solid @white;
				.lb-body {
					.heading,
					.info-wrapper .btn.lb-closer {
						display: none;
					}
				}
			}
		}
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.lb-wrapper {
		&.lb-tcModal {
			.lb-body-wrapper  {
				width: 360px;
				.lb-body {
					.info-wrapper {
						.btn.lb-closer {
							&:hover, &:focus {
								background-color: lighten(@theme-blue, 5%);
							}
							&:active {
								background-color: darken(@theme-blue, 5%);
							}
						}
					}
				}
				.no-js & {
					width: 100%;
				}
			}
		}
	}
}
