.confirmation-info {
	text-align: center;
	h3 {
		font-size: 30px;
		span {
			vertical-align: middle;
		}
		&:before {
			font-size: 50px;
			.font-icon('\f058', '5px');
			vertical-align: middle;
		}
	}
	p {
		font-weight: 500;
		&.details {
			font-size: 18px;
		}
	}
	.btn {
		margin: 30px 0 0;
		&.submit {
			border: none;
			width: 150px;
			height: 46px;
			display: inline-block;
			box-sizing: border-box;
			.buttonInfo(@gray, true);
			border-bottom: 6px solid darken(@gray, 20%);
		}
	}
	.order-info-small {
		margin: 20px 0 0;
		.info {
			margin: 0;
			font-size: 15px;
			& + .sales-order, & ~ .sales-order {
				font-size: 25px;
				text-transform: uppercase;
			}
		}
		.sales-order {
			margin: 0;
			font-size: 20px;
			font-weight: 700;
		}
	}
	&.rejected {
		color: @frontend_rejectionred;
		h3 {
			color: @frontend_rejectionred;
		}
	}
	&.approved {
		color: @frontend_approvegreen;
		h3 {
			color: @frontend_approvegreen;
		}
	}
	.wufoo-survey {
		width: 100%;
		padding: 10px;
		max-width: 620px;
		margin: 20px auto;
		border-radius: 6px;
		box-sizing: border-box;
		background-color: @white;
		border: 1px solid @gray93;
	}
}
