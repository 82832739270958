.action-bar {
	padding: 5px;
	display: block;
	overflow: hidden;
	margin: 15px 0;
	box-sizing: border-box;
	background-color: @gray93;
	border: 1px solid darken(@gray93, 10%);
	&.col {
		display: inline-block;
	}
}

.btn-field {
	display: block;
	margin: 15px 0;
	overflow: hidden;
	&.col {
		display: inline-block;
	}
}

.btn {
	height: auto;
	outline: none;
	display: block;
	font-size: 20px;
	font-weight: bold;
	border-radius: 5px;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	box-sizing: border-box;
	transition: all .2s linear;
	&.small {
		height: auto;
		font-size: 16px;
		padding: 5px 10px;
	}
	&.lfloat,
	&.rfloat {
		margin: 6px 0;
	}
	&.submit,
	&[type="submit"] {
		color: @white;
		.buttonInfo(@orange1);
	}
	&.secondary {
		color: @white;
		.buttonInfo(@steelblue);
	}
	&.additional {
		color: @gray29;
		.buttonInfo(@gray83, false);
	}
	&.delete {
		color: @white;
		.buttonInfo(@firebrick);
	}
	&.approval {
		color: @white;
		.buttonInfo(@forestgreen);
	}
	&.disabled,
	&[disabled] {
		.buttonInfo(@lightgray) !important;
		cursor: not-allowed;
		&:active, &:focus, &:hover  {
			background-color: @lightgray !important;
		}
	}
	.btn-icon {
		margin-right: 3px;
	}
}

.buttonInfo(@color, @useShadow: true) {
	background-color: @color;
	border: 1px solid darken(@color, 10%);
	& when (@useShadow = true) {
		text-shadow: 1px 1px 1px darken(@color, 10%);
	}
	& when (@useShadow = false) {
		text-shadow: none;
	}

	&:hover, &:focus {
		background-color: lighten(@color, 10%);
	}
	&:active {
		background-color: darken(@color, 5%);
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.btn {
		&.lfloat,
		&.rfloat {
			margin: 5px;
		}
	}
}
