header {
	z-index: 1;
	padding: 10px;
	position: relative;
	background-color: @white;
	box-shadow: 0 4px 5px -2px @gray29;
	.nav-menu-opener {
		margin: 0;
		float: right;
		line-height: 1;
		display: block;
		cursor: pointer;
		padding: 5px 14px;
		text-align: center;
		color: @theme-blue;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		span {
			line-height: 1;
			display: block;
			font-size: 12px;
			&:before {
				line-height: 1;
				display: block;
				font-size: 30px;
				.font-icon('\f0c9');
			}
		}
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	header {
		padding: 0;
		.logo-wrapper {
			padding: 19px 20px;
			padding-right: 0;
		}
		.nav-menu-opener {
			padding: 15px 40px;
			transition: all .25s linear;
			span {
				font-size: 16px;
				&:before {
					font-size: 40px;
				}
			}
			&:hover {
				color: @white;
				background-color: darken(@theme-blue, 20%);
			}
		}
	}
}
