@navHeaderHeight: '70px';
@navFooterHeight: '47px';

nav {
	&.nav-menu {
		top: 0;
		right: 0;
		width: 90%;
		z-index: -1;
		height: 100%;
		display: none;
		position: fixed;
		background-color: @gray93;
		#menu_toggle_view_state:checked ~ &,
		#menu_toggle_view_state:checked + .page-slide-wrapper + & {
			display: block;
		}
		.nav-header {
			height: @navHeaderHeight;
			padding: 6px 10px;
			background-color: @white;
			border-bottom: 1px solid @silver;
			.user-info {
				width: 100%;
				color: @theme-blue;
				padding-right: 52px;
				box-sizing: border-box;
				span {
					display: block;
					line-height: 1.1;
					font-size: 12px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					&:before {
						display: block;
						font-size: 40px;
						.font-icon('\f2be', '5px');
					}
				}
			}
			.nav-menu-closer {
				top: 10px;
				margin: 0;
				right: 10px;
				padding: 0 10px;
				cursor: pointer;
				text-align: center;
				position: absolute;
				-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
				span {
					display: block;
					line-height: 1;
					font-size: 12px;
					&:before {
						display: block;
						font-size: 40px;
						.font-icon('\f00d');
					}
				}
			}
		}
		.nav-list-wrapper {
			display: block;
			height: calc(~'100% - (@{navHeaderHeight} + @{navFooterHeight} + 3px)');
			overflow: hidden;
			.nav-list {
				margin: 0;
				padding: 0;
				color: @gray29;
				list-style: none;
				li {
					border-bottom: 1px solid @silver;
					.accord-action {
						height: 55px;
						color: @gray47;
						font-size: 20px;
						font-weight: 500;
						padding: 15px 10px;
						&:after {
							top: 16px;
							right: 16px;
							font-size: 25px;
						}
						&.active {
							color: @black;
							border-bottom: 2px solid @silver;
						}
					}
					.accord-content {
						height: auto;
						overflow: auto;
						max-height: 300px;
						max-height: calc(100vh - (75px + 45px + 1px + (55px * 3)));
						background-color: @silver;
						h3 {
							margin: 0;
							font-size: 16px;
							font-weight: 500;
							padding: 5px 10px;
							background-color: @silver;
						}
						.proof-viewers {
							i {
								color: @frontend_approvegreen;
								&.rejected {
									color: @frontend_rejectionred;
								}
							}
						}
					}
					&.versions {
						.accord-content {
							a, p {
								margin: 0;
								color: @black;
								display: block;
								font-size: 16px;
								font-weight: 500;
								padding: 10px 5px;
								text-decoration: none;
								box-sizing: border-box;
								border-bottom: 1px solid darken(@gray83, 15%);
								&.odd {
									background-color: @gray83;
								}
								&.even {
									background-color: darken(@gray83, 7%);
								}
								.status {
									color: @theme-blue;
								}
								.viewing {
									color: @gray47;
									font-size: 12px;
									font-weight: 400;
								}
								&:before {
									width: 20px;
									.font-icon('', '5px');
									text-align: center;
									display: inline-block;
									vertical-align: middle;
									text-shadow: 1px 1px 1px @gray60;
								}
								&.rejected {
									.status {
										color: @frontend_rejectionred;
									}
									&:before {
										color: @frontend_rejectionred;
										.font-icon('\f023', '5px');
									}
								}
								&.approved {
									.status {
										color: @frontend_approvegreen;
									}
									&:before {
										color: @frontend_approvegreen;
										.font-icon('\f00c', '5px');
									}
								}
							}
						}
					}
				}
			}
		}
		.nav-footer {
			left: 0;
			bottom: 0;
			width: 100%;
			position: absolute;
			height: @navFooterHeight;
			a, label {
				margin: 0;
				padding: 10px;
				color: @gray47;
				display: block;
				cursor: pointer;
				text-decoration: none;
				border-top: 1px solid @silver;
				&:after {
					right: 10px;
					position: absolute;
					.font-icon('\f05a');
				}
			}
		}
	}
}


/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	@navHeaderHeight: '112px';
	@navFooterHeight: '47px';
	nav {
		&.nav-menu {
			z-index: 2;
			width: 75%;
			right: -100%;
			display: block;
			background-color: @gray23;
			transition: right .2s ease-out;
			box-shadow: 10px 0px 10px 10px @black;
			#menu_toggle_view_state:checked ~ &,
			#menu_toggle_view_state:checked + .page-slide-wrapper + & {
				right: 0;
			}
			.nav-header {
				padding: 0;
				height: @navHeaderHeight;
				background-color: @theme-blue;
				.user-info {
					color: @white;
					padding: 15px 0;
					padding-right: 90px;
					text-align: center;
					span {
						font-size: 14px;
						&:before {
							font-size: 60px;
						}
					}
				}
				.nav-menu-closer {
					top: 0;
					right: 0;
					padding: 19px 21px;
					color: @white;
					transition: all .25s linear;
					span {
						font-size: 14px;
						&:before {
							font-size: 60px;
						}
					}
					&:hover, &:focus {
						background-color: darken(@theme-blue, 20%);
					}
				}
			}
			.nav-list-wrapper {
				display: block;
				height: calc(~'100% - (@{navHeaderHeight} + @{navFooterHeight} + 3px)');
				.nav-list {
					color: @sgilightgray;
					li {
						a {
							&.accord-action {
								color: @sgilightgray;
								&.active {
									color: @white;
									border-bottom-color: @white;
								}
							}
						}
						.accord-action {
							height: 65px;
							font-size: 25px;
							padding: 20px 35px;
							&:after {
								top: 25px;
								right: 35px;
							}
						}
						.accord-content {
							max-height: 400px;
							max-height: calc(100vh - (117px + 45px + 1px + (65px * 3)));
							background-color: @gray23;
							h3 {
								margin: 0;
								font-size: 16px;
								font-weight: 500;
								padding: 5px 10px;
								color: @sgilightgray;
								background-color: @gray23;
							}
							p {
								margin: 0;
								padding: 5px 10px;
							}
							.tbl-div {
								.first {
									padding-left: 20px;
								}
								.last {
									padding-right: 20px;
								}
								.even {
									color: @white;
									background-color: @gray23;
								}
								.odd {
									color: @white;
									background-color: darken(@gray23, 8%);
								}
								.tbl-head {
									color: @white;
									background-color: @black;
								}
								.tbl-col {
									padding-top: 10px;
									padding-bottom: 10px;
									&.first {
										border-right: 1px solid darken(@lightgray, 30%)
									}
								}
							}
						}
						&.versions {
							.accord-content {
								a, p {
									font-size: 18px;
									padding: 10px 30px;
									&.odd {
										color: @white;
										background-color: darken(@gray23, 8%);
									}
									&.even {
										color: @white;
										background-color: @gray23;
									}
									.viewing {
										font-size: 14px;
									}
									&:before {
										text-shadow: 1px 1px 1px @gray23;
									}
								}
							}
						}
					}
				}
			}
			.nav-footer {
				height: @navFooterHeight;
				a, label {
					padding: 12px;
					font-size: 18px;
					text-align: center;
					color: @sgilightgray;
					border-top: 1px solid @sgilightgray;
					&:after {
						right: auto;
						font-size: 16px;
						margin-left: 10px;
						position: relative;
					}
				}
			}
		}
	}

}
